import React from 'react';
import Graph from '../../components/Graph/Graph.jsx'; // Import the component
import DoughnutGraph from '../../components/Graph/DoughnutGraph.jsx'; // Import the component
import { constituency } from '../../components/Graph/Graph.jsx';


class GraphView extends React.Component {
  render() {

    if(constituency !== null) {
      return (
        <div className='chart-container'>
          <Graph/>
        </div>
      );
    } else {
      return (
        <div className='chart-container'>
          <Graph/>
          <DoughnutGraph/>
        </div>
      );
    }
    
  }
}

export default GraphView;