import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Admin from "../views/Admin/Admin";
import Election from "../views/ElectionPage/Election";
import Meta from "../views/ElectionPage/Meta";
import Card from "../components/Card/Card";
import NationalCard from "../components/Card/NationalCard";
import SpreadGraph from "../components/Graph/SpreadGraph";
import Table from '../components/Table/Table';
import GraphView from "../views/GraphView/GraphView";
import CardMetadata from '../components/Card/CardMetadata';


const Router = () => {

  let url = new URL(window.location.href);

  switch (url.searchParams.get("component")) {
    case "election":
      return (<Election />)
    case "table":
      return (<Table />)
    case "card":
      return (<Card />)
    case "cardmetadata":
      return (<CardMetadata />)
    case "graph":
      return (<GraphView />)
    case "nationalcard":
      return (<NationalCard />)
    case "spreadgraph":
      return (<SpreadGraph />)
    case "meta":
      return (<Meta />)
    default:
      return;
  }

  // return (
  //   <BrowserRouter>
  //     <Routes>
  //       {/* <Route exact path="/" element={<Admin />}></Route> */}
  //       <Route exact path="/election/:id" element={<Election />}></Route>
  //       <Route exact path="/table/" element={<Table />}></Route>
  //       <Route exact path="/card/" element={<Card />}></Route>
  //       <Route exact path="/graph/" element={<GraphView />}></Route>
  //       <Route exact path="/nationalcard/:id" element={<NationalCard />}></Route>
  //       <Route exact path="/spreadgraph/:id" element={<SpreadGraph />}></Route>
  //       <Route exact path="/meta/:id" element={<Meta />}></Route>
  //       <Route exact path="/card/metadata/" element={<CardMetadata />}></Route>
  //     </Routes>
  //   </BrowserRouter>
  // );
};
export default Router;
