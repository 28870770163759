import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import electionAPIData from "../../utils/election-api";
import spreadsheetAPIData from "../../utils/spreadsheet-api";
import colorFeeds from "../Data/coloursData.json";
import './graph.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Declare variables needed later on
var backgroundColors;
var firstPreferenceData = [];
var labels = [];
var seatsData = [];
var returnedData;
var chartObject;
var hideSeats;
var hideFirstPref;
var isNULL;

// Pull the params from the URL
var url_string = window.location.href; 
var url = new URL(url_string);
export var electionCode = url.searchParams.get("election");
export var constituency = url.searchParams.get("constituency");
var mode = url.searchParams.get("mode");

// Show / Hide the seat and first pref labels based on URL params
if(mode === "seats") {
    chartObject = 0;
    hideSeats = false;
    hideFirstPref = true;
} else if (mode === "firstpref") {
    chartObject = 1;
    hideSeats = true;
    hideFirstPref = false;
}

const Graph = () => 
{
    const [electionData, setElectionData] = useState({});
    
    useEffect(() => 
    {
        // Fetch data - start
        if(electionCode === "local24") {
            // Spreadsheet function to fetch data for locals
            spreadsheetAPIData()
              .then(data => {
                returnedData = data.parties;
                
                // Check if the firspref field is returning the correct data
                for (let party in returnedData) {
                    if (returnedData[party]["firstpreference"] === null) {
                        returnedData[party]["firstpreference"] = 0;
                    }
                }

                setElectionData(returnedData);
                if(data.turnout > 0) {
                    isNULL = false;
                } else {
                    isNULL = true;
                }
            })
              .catch(error => {
                console.error('Error:', error);
            });
        } else {
            // Form query parameters
            var endpointParams = '';

            if(constituency) {
                endpointParams = electionCode + '/constituencies/' + constituency + '/fulldata';
            } else {
                endpointParams = electionCode + '/nationalpage';
            }

            // For European Elections
            electionAPIData(endpointParams)
            .then((data) => { 
                if(constituency) {
                    if(typeof data.meta.turnout !== "undefined" && data.meta.turnout > 0) {
                        returnedData = data.voteShareOrder; //Eg feed: https://qa.irishtimes.com/election-api/api/public/v1/elections/qaeur19/constituencies/MW2/fulldata
                        isNULL = false;
                    } else {
                        returnedData = data.nullStateParties;
                        isNULL = true;
                    }
                } else {
                    if(data.turnout > 0) {
                        returnedData = data.voteShareOrder; // Eg feed: https://qa.irishtimes.com/election-api/api/public/v1/elections/qaeur19/nationalpage
                        isNULL = false;
                    } else {
                        returnedData = data.nullPartyStates;
                        isNULL = true;
                    }
                }
                setElectionData(returnedData);
            })
            .catch((err) => {
                console.log(err.message);
            }); 
        }
        // Fetch data - end  
    }, []);

    // Adjust the values for european contituency 
    if(constituency) {
        labels = Object.values(electionData).map(party => party.party);
        seatsData = Object.values(electionData).map(party => party.seats);
        firstPreferenceData = Object.values(electionData).map(party => party.firstpreference);
        for (var key in seatsData) {
            if (seatsData[key] === undefined) {
                seatsData[key] = 0;
            }
        }
    } else {
        labels = Object.keys(electionData);
        seatsData = Object.values(electionData).map(party => party.seats);
        firstPreferenceData = Object.values(electionData).map(party => party.firstpreference);
    }

    

    // Set the background colours
    const setColors = (returnedData) => {
        let colorArray = [];
        labels.forEach(returnedData => {
            colorArray.push(colorFeeds[0][returnedData.toLowerCase()]);
        });
        
        return colorArray;
    };
    backgroundColors = setColors(returnedData);


    // Seats and First Pref 
    const floatingLabels = {
        id: 'floatingLabels',
        afterDatasetsDraw(chart, args, options)
        {
            // Retrieve data from chart object
            const { ctx, chartArea: { top, bottom, left, right }, scales: { x, y } } = chart;
            for ( let i = 0; i < chart.config.data.datasets[chartObject].data.length; i++) 
            {
                // Where on the x-axis do I want the labels to show?
                // For resizing, i'm calculating where to put the primary dat column based on a percentage of the chart width
                const primaryDataPercentage = 10;
                const secondaryDataPercentage = 80;
                const xPositionColumnOne = right - secondaryDataPercentage;
                const xPositionColumnTwo = right - primaryDataPercentage; // This column shows the primary data
                
                // Y position is based on where the row is
                const yPosition = chart.getDatasetMeta(chartObject).data[i].y;
                const yPositionText = chart.getDatasetMeta(chartObject).data[i].y + 5;
                const yPositionStart = chart.getDatasetMeta(chartObject).data[0].y;
                const paddingLeft = 0;
                                 
                // Get data from chart
                const seats = chart.config.data.datasets[0].data[i];
                const labels = chart.config.data.labels[i];
                var firstPref = chart.config.data.datasets[1].data[i].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                
                ctx.save(); 

                // Party Names - Background
                 var backgroundHeight = chart.getDatasetMeta(chartObject).data[i].height;
                 var backgroundWidth = 45;
                 var backgroundY = chart.getDatasetMeta(chartObject).data[i].y;

                // x, y, width, height
                ctx.fillStyle = chart.getDatasetMeta(chartObject).data[i].options.backgroundColor;
                ctx.fillRect(paddingLeft, backgroundY - (backgroundHeight /2), backgroundWidth, backgroundHeight);

                // Font size is calculated dynamically based on the width of the chart, and it's adjusted to be between a minimum of 11px and a maximum of 16px
                var fontSize = 16;

                var labelFont = 'Roboto Condensed';

                // Party Names - Label
                ctx.font = fontSize +'px ' + labelFont;
                ctx.textAlign = 'left'; 
                ctx.fillStyle = 'white';
                var textWidth = ctx.measureText(labels).width;
                ctx.fillText(labels, paddingLeft + backgroundWidth/2 - textWidth/2, yPositionText); 

                // Line under rect
                ctx.beginPath();
                ctx.moveTo(paddingLeft, yPosition + yPositionStart);
                ctx.lineTo(chart.chartArea.left, yPositionStart + yPosition)
                ctx.strokeStyle = '#E5E5E5';
                ctx.stroke();
                // Seats and First pref column labels
                ctx.textAlign = 'right';
                ctx.font = fontSize + 'px ' + labelFont;
                ctx.fillStyle = 'black'; 

                if(mode === 'seats') {
                    //First Pref Label (Secondary)
                    ctx.fillText(firstPref  + '%', xPositionColumnOne, yPositionText);

                    //Seats label (Primary)
                    ctx.font = 'bold ' + fontSize + 'px ' + labelFont;  
                    ctx.fillText(seats, xPositionColumnTwo, yPositionText);
                } else if(mode === 'firstpref') {

                    if(isNULL === true) {
                         // Null Label (Primary)
                        ctx.font = 'bold ' + fontSize + 'px ' + labelFont;  
                        ctx.fillStyle = 'black';                  
                        ctx.fillText('---', xPositionColumnTwo, yPositionText);
                    } else {
                        // Seats Label (Secondary)
                        ctx.fillText(seats, xPositionColumnOne, yPositionText);
                        
                        //First Pref Label (Primary)
                        ctx.font = 'bold ' + fontSize + 'px ' + labelFont;  
                        ctx.fillText(firstPref  + '%', xPositionColumnTwo, yPositionText);
                    }
                } 
            }
        } 
    }

    function getMaxValue() {
        // The reason the max values are hard coded is to stop the bars from drawing over the firstpref and seats labels
        var maxValue;
        var firstPrefMax = 70;

        if (electionCode === "local24") {
            maxValue = (mode === "seats") ? 678 : firstPrefMax;
        } else {
            const constituencies = ["DB2", "IS2", "MW2"];
            if (constituencies.includes(constituency)) {
                maxValue = (mode === "seats") ? 3 : firstPrefMax;
            } else {
                maxValue = (mode === "seats") ? 10 : firstPrefMax;
            }
        }
        return maxValue;      
    }

    const options = {
        plugins: 
        {
            tooltip: 
            {
                enabled: false, // Disable tooltips
            },
            legend: 
            {
                display: false
            },
        },
        layout: 
         {
          padding: 
          {
              left: 45
          }
         },
        scales: 
        {
            y: 
            {
                ticks: 
                {
                  display: false,
                  color: 'white',
                  font: {
                      weight: 'bold',
                      size: '20px',
                  },
                  backdropColor: backgroundColors,
                  showLabelBackdrop: true,
                }
            },
            x: 
            {
              beginAtZero: true,
              max: getMaxValue(),
              grid: 
              {
                  display: false
              },
              ticks: 
              {
                  display: false
              }
            },
        },
        animation: false,
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        type: 'bar',
      };

    const chartData = {
        labels,
        datasets: [
            {
                labels: 'Seats',
                data: seatsData,
                backgroundColor: backgroundColors,  
                hidden: hideSeats
            },
            {
                labels: 'First Pref',
                data: firstPreferenceData,
                backgroundColor: backgroundColors, 
                hidden: hideFirstPref
            },            
        ],
        
    };

    function BarHeader(){
        let middleHeader;
        let lastHeader;

        const firstPrefHeader = <p className='firstPrefHeader'>1<sup>ST</sup> PREF</p>
        const seatsHeader = <p>SEATS</p>
        const firstHeader = <p>PARTY</p>

        if(mode === 'seats') {
            middleHeader = firstPrefHeader;
            lastHeader = seatsHeader;
        }
        else if(mode === 'firstpref') {
            if(isNULL === true) {
                middleHeader = '';
                lastHeader = firstPrefHeader

            } else {
                middleHeader = seatsHeader
                lastHeader = firstPrefHeader
            }
        }

        return (
            <>
            <div className='bar-header'>
                {firstHeader}
                {middleHeader}
                {lastHeader}
            </div>
            </>
        )
    };

    return (
        <div className="bar-chart-container">
            <BarHeader />
            <div className='chart-wrapper'>
                <Bar options={options} plugins={[floatingLabels]} data={chartData} />
            </div>
        </div>
    );
};

export default Graph;