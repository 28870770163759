import axios from 'axios';

export default async function spreadsheetAPIData() {

    const dataURL = window.location.origin + '/election-data.json';
    const headers = ['Party codes', '% 1st pref', 'seats', 'turnout', 'constituenciescompleted', 'National turnout'];
    let parsedCsvData;


    try {
      const electionCodeFilePath = "./static/election-data/local24/nationalpage.json";

      const staticData = await axios.get(electionCodeFilePath);

      return Promise.resolve(staticData.data);

    } catch (staticDataError) {

      console.log("No Static Data Found: " + staticDataError);

      const graphData = axios.get(dataURL) // Use Axios to fetch the CSV data
      .then((response) => {
          //parsedCsvData = parseCSV(response.data);

          const dataString = JSON.stringify(response.data);
          const spreadsheetData = JSON.parse(dataString);

          return spreadsheetData;
        })
        .catch((error) => {
          console.error("Error fetching CSV data:", error);
        });

        return graphData
    }


    function parseCSV(csvText) {
        const rows = csvText.split(/\r?\n/);
        const headers = rows[0].split(',');
        const data = [];
        for (let i = 1; i < rows.length; i++) {
            const rowData = rows[i].split(',');
            const rowObject = {};
            for (let j = 0; j < headers.length; j++) {
                rowObject[headers[j]] = rowData[j];
            }
            data.push(rowObject);
        }
        return data;
    }

    function convertToJSON(values) {
        const national = values[0];
        var nationalData = {};
        nationalData[headers[4]] = parseInt(national["Constituencies complete"]);
        nationalData[headers[3]] = parseInt(national["National turnout"]);
        var partyData = {};
        for (var i = 0; i <= 7; i++) {
            var row = values[i];
            var jsonObject = {};
            jsonObject["% 1st pref"] = parseFloat(row["% 1st pref"]);
            jsonObject["seats"] = parseInt(row["Seats"]);
            partyData[row["Party codes"]] = jsonObject;
        }
        nationalData["parties"] = partyData;
        return JSON.stringify(nationalData);
    }


}