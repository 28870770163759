import React, { useState, useEffect } from "react";
import "./CardMetadata.css";
import constituenciesJson from "../Data/constituencies.json";
import electionAPIData from "../../utils/election-api";
import spreadsheetAPIData from "../../utils/spreadsheet-api";
import colorFeeds from "../Data/coloursData.json";

const CardMetadata = () => {
  const [partyColors, setPartyColors] = useState([]);
  const [seats, setSeats] = useState([]);
  const [metadata, setMetadata] = useState([]);

  useEffect(() => {
    // Pull the params from the URL
    var url_string = window.location.href;
    var url = new URL(url_string);
    var electionCode = url.searchParams.get("election");
    var constituency = url.searchParams.get("constituency");
    /*
      electionCode - qagen2016 / QA2022 / ...
      constituency (code) - CET / CKY / CLR / TIP / ...
    */

    // Declare the party colours
    setPartyColors(colorFeeds);

    // Get number of seats filled in specified constituency
    const endpointParamsSeats = electionCode + '/nationalpage';
    electionAPIData(endpointParamsSeats)
      .then((data) => {
          // Update the array - 'code' (constituenciesJson) and 'name.toLowetCase()'
          const updatedConstituencies = constituenciesJson[electionCode].map(item => {
          const match = data.constituencies.find(constituency => constituency.name.replace(/\s+/g, '-').toLowerCase() === item.name.replace(/\s+/g, '-').toLowerCase());
          if (match && data.ceanncomhairle && data.ceanncomhairle.constituencyName === match.name ) {
            match.elected.push({name: data.ceanncomhairle.name, party: data.ceanncomhairle.party, firstpreference: 16.501232317094, count: '6'})
          }
          if (match) {
            return { ...item, ...match };
          }
          return item;
        })
        const foundConstituency = updatedConstituencies.find(item => item.code === constituency);
        setSeats(foundConstituency.elected ? foundConstituency.elected : []);
      })
      .catch((err) => {
        console.log(err.message);
      });

    // Get brief set of data for specified constituency   
    const endpointParams = electionCode + "/constituencies/" + constituency + "/data";
    electionAPIData(endpointParams)
      .then((data) => {
        // If the array is empty (nullState), use "constituenciesJson" to get the "Seats" value
        if (data.length === 0) { 
          const nullState = constituenciesJson[electionCode].find(
            (item) => item.code === constituency
          );
          setMetadata(nullState);
        } else {
          setMetadata(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // Seats filled
  function Seats(props) {
    let seatsParty = [];
    for (let i = 0; i < props.totalseats; i++) {
      let partyName = props.seatsfilled[i] ? props.seatsfilled[i].party.toLowerCase() : "";
      // Set the party colour
      let electedBg = {
        backgroundColor: partyColors[0][partyName],
      };
      seatsParty.push(<div key={i} className="seats" style={electedBg}></div>);
    }
    return seatsParty;
  }

  // Metadata
  function Table(props) {
    return (
      <>
        <tr>
          <td>Electorate</td>
          <td className="metadata-td">
            {props.meta?.electorate ? props.meta.electorate.toLocaleString('en-US') : "..."}
          </td>
        </tr>
        <tr>
          <td>Quota</td>
          <td className="metadata-td">
            {props.meta?.quota ? props.meta.quota.toLocaleString('en-US') : "..."}
          </td>
        </tr>
        <tr>
          <td>Total Poll</td>
          <td className="metadata-td">
            {props.meta?.totalpoll ? props.meta.totalpoll.toLocaleString('en-US') : "..."}
          </td>
        </tr>
        <tr>
          <td>Spoiled</td>
          <td className="metadata-td">
            {props.meta?.spoiledvotes ? props.meta.spoiledvotes.toLocaleString('en-US') : "..."}
          </td>
        </tr>
        <tr>
          <td>Valid Poll</td>
          <td className="metadata-td">
            {props.meta?.totalvalidpoll ? props.meta.totalvalidpoll.toLocaleString('en-US') : "..."}
          </td>
        </tr>
        <tr>
          <td>Turnout</td>
          <td className="metadata-td">
            {props.meta?.turnout ? `${props.meta.turnout.toFixed(2)}%` : "..."}
          </td>
        </tr>
      </>
    );
  }

  return (
    <div className="CardMetadata">
      <div className="metadata">
        <table className="metadata-table">
          <tbody>
            <tr>
              <td>Seats</td>
              <td className="metadata-td">
                <div className="seats-td">
                  <Seats totalseats={metadata?.seats} seatsfilled={seats} />
                </div>
              </td>
            </tr>
            <Table meta={metadata} />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CardMetadata;
