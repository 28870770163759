import React, { useState, useEffect } from 'react';

var backgroundColors;

const Meta = () => {
    const [metaData, setMetaData] = useState([]);
    const [seatData, setSeatData] = useState([]);
    var url_string = window.location.href; 
    
    useEffect(() => {
      var url_string = window.location.href; 
      var url = new URL(url_string);
      var election = url.searchParams.get("election");
      var datatype = url.searchParams.get("datatype");
      var constituency = url.searchParams.get("constituency");

      const blackColor = 'rgb(51, 51, 51)';
      const colorFeeds = {
        sf: 'rgb(39, 112, 70)',
        ff: 'rgb(64, 179, 79)',
        fg: 'rgb(47, 53, 144)',
        io: blackColor,
        wua: blackColor,
        ind: blackColor,
        oth: blackColor,
        gp: 'rgb(180, 209, 68)',
        lab: 'rgb(199, 40, 49)',
        sd: 'rgb(102, 0, 230)',
        spbp: 'rgb(196, 86, 139)',
      }
     //let metaData;
      fetch('https://www.irishtimes.com/election-api/api/public/v1/elections/gen2016/constituencies/TIP/fulldata?ts=1713179655856')
          .then((response) => response.json())
          .then((data) => {
              let dataSet = [];
              let nullValue = '--';
              Object.keys(data.meta).forEach(key => {
                dataSet[key] = data.meta[key] === undefined || data.meta[key] === null ? nullValue : data.meta[key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              });
              setSeatData(data.filled);
              setMetaData(dataSet);
              
          })
          .catch((err) => {
              console.log(err.message);
          });
      }, []);
      function Meta(props) {
        return  (
          <div>
            <div className="constituencySeatsSpread">Seats{seatData.map(seats => (
                <span>{seats.party}</span>
              ))}
            </div>
            <div className="constituencyElectorate">Electorate <span>{metaData.electorate}</span></div>
            <div className="constituencyQuota">Quota <span>{metaData.quota}</span></div>
            <div className="constituencySeatsSpread">Total Poll. <span>{metaData.totalpoll}</span></div>
            <div className="constituencySeatsSpread">Spoiled <span>{metaData.spoiled}</span></div>
            <div className="constituencySeatsSpread">Valid Poll <span>{metaData.totalvalidpoll}</span></div>
            <div className="constituencySeatsSpread">Turnout <span>{metaData.turnout}%</span></div>
          </div>
        )
      }
    return (
    <div>
      <Meta  />
    </div>
  );
};


export default Meta;