import React, { useState, useEffect } from 'react';
import constituencies from "../Data/constituencies.json";
import ceannComhairle from "../Data/ceannComhairle.json";
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css';
import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
var backgroundColors;

const NationalCard = () => {
    const [partyData, setPartyData] = useState([]);
    const [seatData, setSeatData] = useState([]);
    const [columnData, setColumnData] = useState([]);
    var url_string = window.location.href; 
    
    useEffect(() => {
      const blackColor = 'rgb(51, 51, 51)';
      const colorFeeds = {
        sf: 'rgb(39, 112, 70)',
        ff: 'rgb(64, 179, 79)',
        fg: 'rgb(47, 53, 144)',
        io: blackColor,
        wua: blackColor,
        ind: blackColor,
        oth: blackColor,
        gp: 'rgb(180, 209, 68)',
        lab: 'rgb(199, 40, 49)',
        sd: 'rgb(102, 0, 230)',
        spbp: 'rgb(196, 86, 139)',
      };
      fetch('https://www.irishtimes.com/election-api/api/public/v1/elections/GE2020/nationalpage?ts=1713440126015')
            .then((response) => response.json())
            .then((data) => {
                let columns = [];
                columns.push({ title: 'Party', field:  'party'});
                columns.push({ title: 'Count', field:  'count'});
                columns.push({ title: 'First Pref', field:  'firstpreference'});
                columns.push({title:"Seats", field:"seats", sorter:"number", formatter: function(cell, formatterParams, onRendered) {
                    let value = cell.getValue();
                    let votePercent = value / data.seatsfilled * 100;
                    let party = cell.getRow().getData().party.toLowerCase(); // Get color property from data
                    console.log(party);
                    let bar = "<div style='background-color: " + colorFeeds[party] + "; height: 20px; width: " + votePercent + "px;'></div>";
                    return bar;
                }});
                const voteData = [];
                for (const [key, value] of Object.entries(data.parties)) {
                  voteData.push({'party': key, 'count': value.seats, 'firstpreference': value.firstpreference, 'seats': value.seats})
                }
                setColumnData(columns);
                setPartyData(voteData);
                console.log(voteData)
                console.log(columns)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
      function NationalCard(props) {
        
      }
    return (
      <div style={{ width: '100%' }}>
        <h1>National Table</h1>
        <ReactTabulator data={partyData} columns={columnData} layout={"fitData"} />
      </div>
  );
};


export default NationalCard;