import React, { useState, useEffect } from 'react';
import electionAPIData from "../../utils/election-api";
import spreadsheetAPIData from "../../utils/spreadsheet-api";
import '../../App.css'; 
import electionMaxData from "../Data/electionMaxData.json";

import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { electionCode } from '../../components/Graph/Graph.jsx';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ArcElement,
    Legend
);


// Call the hard coded max values for each election
const maxEuropeanConstituencies = electionMaxData[0].electionMaxData.maxEuropeanConstituencies;
const maxLocalConstituencies = electionMaxData[0].electionMaxData.maxLocalConstituencies;
const maxLocalSeats = electionMaxData[0].electionMaxData.maxLocalSeats;
const maxEuropeanSeats = electionMaxData[0].electionMaxData.maxEuropeanSeats;

// Declare variables needed later on
var seatsValue;
var constituenciesValue;
var seatsLabel;
var constituencyLabel
var turnoutLabel;

const DoughnutGraph = () => 
{
    // Create states for each of the doughbut charts data
    var [seatsData, setSeatsData] = useState([]);
    var [constituencyData, setConstituencyData] = useState([]);
    var [turnoutData, setTurnoutData] = useState([]);

    useEffect(() => 
    {
        // Create the endpoint to be passed into the election API fro Europeans
        var endpointParams = electionCode + '/nationalpage';

        // Fecth data - start
        if(electionCode === 'local24') {
            
            // Retrieve the local election data from the Google Sheet via the spreadsheet API
            spreadsheetAPIData()
              .then(data => {                
                setTurnoutData(data.turnout);
                setSeatsData(data.seatsfilled);
                setConstituencyData(data.constituenciescompleted);

              })
              .catch(error => {
                console.error('Error:', error);
            });

        } else {
            // Retrieve the election data from NERD
            electionAPIData(endpointParams)
            .then((data) => {
                setSeatsData(data.seatsfilled);
                setConstituencyData(data.constituenciescompleted);
                setTurnoutData(data.turnout);
            })
            .catch((err) => {
                console.log(err.message);
            });    
        }
        // Fetch data - end


    }, []);

    // Set the labels text for each of the charts
    if(electionCode === 'local24') {
        seatsLabel = seatsData + " / " + maxLocalSeats;
        constituencyLabel = constituencyData + " / " + maxLocalConstituencies;
        seatsValue = maxLocalSeats;
        constituenciesValue = maxLocalConstituencies;

    } else {
        seatsLabel = seatsData + " / " + maxEuropeanSeats;
        constituencyLabel = constituencyData + " / " + maxEuropeanConstituencies;
        seatsValue = maxEuropeanSeats;
        constituenciesValue = maxEuropeanConstituencies;
    }

    turnoutLabel = turnoutData.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%";    

    var labelFont = "Roboto Condensed";

    // Center Text Plugin Block
    const centerText = {
        id: 'centerText',
        afterDatasetsDraw(chart, args, pluginOptions)
        {
            const { ctx } = chart;
            ctx.save();
            // Font size is calculated dynamically based on the width of the chart, and it's adjusted to be between a minimum of 10px and a maximum of 25px
            var dynamicFontSize = Math.max(7, Math.min(20, Math.round(chart.width * 0.1)));
            const text = chart.getDatasetMeta(0).label;
            const x = chart.getDatasetMeta(0).data[0].x;
            const y = chart.getDatasetMeta(0).data[0].y;
            ctx.textAlign = 'center';
            ctx.textBasline = 'middle';
            ctx.font = 'bold ' + dynamicFontSize + 'px ' + labelFont;
            var lineHeight = ctx.measureText('M').width;
            ctx.fillText(text, x, y + lineHeight/2);
        }
    }

    const seatsChartdata = {
        datasets: [{
          label: seatsLabel,
          data: [seatsData, seatsValue-seatsData],
        }]
    };

    const constituencyChartdata = {
        datasets: [{
          label: constituencyLabel,
          data: [constituencyData, constituenciesValue-constituencyData],
        }]
    };

    const turnoutChartdata = {
        datasets: [{
          label: turnoutLabel,
          data: [turnoutData, 100-turnoutData]
        }]
    };

    const seatsChartOptions = 
    {
        plugins: 
        {
            tooltip: 
            {
                enabled: false, // Disable tooltips
            },
            legend: 
            {
                display: false
            },
            title: {
                display: true,
                text: 'SEATS',
                font: {
                    size: 15,
                    family: labelFont,
                    weight: 'normal'
                },
                position: 'bottom',
            },
        },
        cutout: '70%',
        responsive: true,
        animation: false,
        backgroundColor: [
            "#50748c", // Fill colour
            "#dadada" //Not filled colour
        ]
    };   
    
    const constituencyChartOptions = 
    {
        plugins: 
        {
            tooltip: 
            {
                enabled: false, // Disable tooltips
            },
            legend: 
            {
                display: false
            },
            title: {
                display: true,
                text: 'CONSTITUENCIES',
                font: {
                    size: 15,
                    family: labelFont,
                    weight: 'normal'
                },
                position: 'bottom',
            },
        },
        cutout: '70%',
        responsive: true,
        animation: false,
        backgroundColor: [
            "#50748c", // Fill colour
            "#dadada" //Not filled colour
        ]
    };    

    const turnoutChartOptions = 
    {
        plugins: 
        {
            tooltip: 
            {
                enabled: false, // Disable tooltips
            },
            legend: 
            {
                display: false
            },
            title: {
                display: true,
                text: 'TURNOUT',
                font: {
                    size: 15,
                    family: labelFont,
                    weight: 'normal'
                },
                position: 'bottom',
            },
        },
        cutout: '70%',
        responsive: true,
        animation: false,
        backgroundColor: [
            "#50748c", // Fill colour
            "#dadada" //Not filled colour
        ]
    };    

    return (
        <div className="doughnut-chart-container">
            <div className="doughnut-chart">
                <Doughnut plugins={[centerText]} data={seatsChartdata} options={seatsChartOptions} />
            </div>
            <div className="doughnut-chart">
                <Doughnut plugins={[centerText]} data={constituencyChartdata} options={constituencyChartOptions} />
            </div>
            <div className="doughnut-chart">
                <Doughnut plugins={[centerText]} data={turnoutChartdata} options={turnoutChartOptions} />
            </div>
        </div>
    );
};

export default DoughnutGraph;